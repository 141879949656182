<template>
  <div>
    <link
      rel="stylesheet"
      :href="FastTheme"
    >

    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Minhas Plataformas</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
        <!-- fd-app-welcome -->
        <section class="fd-app-welcome">
          <h1>
            Bem vindo,
            <span>{{ nomeUsuario }}</span>
          </h1>
          <p>O que fazemos hoje?</p>
          <img :src="imageFormEffect01">
          <select
            class="custom-select w-50 ml-auto mr-auto mt-4 d-none"
            @change="onChangeTheme($event)"
          >
            <option
              value
              selected
            >
              Tema padrão
            </option>
            <option value="https://senactech.com.br/css/styles1.css">
              Dark
            </option>
          </select>
        </section>
        <!-- /fd-app-welcome -->
        <!-- fd-app-plataformas -->
        <section class="fd-app-plataformas">
          <!-- fd-app-plataformas-action -->
          <div class="fd-app-plataformas-action">
            <a
              v-if="$store.state.fastUsuarioAdministra"
              id="btnAddPlataforma"
              href="cadastro-plataforma"
              class="btn btn-primary btn-lg"
              type="button"
              @click.prevent="$router.push('/cadastro-plataforma')"
            >+ Adicionar Plataformas</a>
          </div>
          <!-- /fd-app-plataformas-action -->
          <div class="fd-app-plataformas-body">
            <div
              v-if="minhasPlataformasLoading"
              class="fd-app-plataformas-list"
            >
              <div class="row">
                <div class="col-12 text-center mt-4">            
                  <h4>
                    <b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando plataformas
                  </h4>
                </div>
              </div>
            </div>
            <div
              v-else
              class="fd-app-plataformas-list"
            >
              <!-- Novo html -->
              <div class="row">
                <div class="col-12 text-center mt-4">
                  <h4 v-if="!fastIdPlataformaMaeFixa">
                    Plataformas encontradas:
                    {{ $store.state.fastMinhasPlataformasLista.length }}
                  </h4>
                  <!-- Botão para limpar cache (oculto) -->
                  <div class="d-none">
                    <small v-if="buscandoCache">
                      Resultado em cache &nbsp;
                      <button
                        class="btn btn-sm btn-danger pt-0 pb-0"
                        @click="buscaPlataformasMaesFilhas"
                      >
                        Limpar
                      </button>
                    </small>
                  </div>
                </div>
              </div>
              <div
                v-if="!fastIdPlataformaMaeFixa"
                class="fd-app-hierarquia-plataformas-body-search"
              >
                <!-- Plugin de autocomplete https://github.com/charliekassel/vuejs-autocomplete#readme -->
                <autocomplete
                  placeholder="Pesquisa"
                  input-class="p-3"
                  results-display="nome_plataforma"
                  :source="$store.state.fastMinhasPlataformasLista"
                  @selected="destacaPlataformaNova"
                />
              </div>

              <div
                id="divListaPlataformas"
                class="row"
              >
                <!-- Recupera params do usuario caso o componente filho envie -->
                <component
                  :is="RptMinhasPlataformasTemplate"
                  :node="$store.state.fastMinhasPlataformas"
                />
              </div>
            </div>
            <div class="mt-5">
              <a
                class="btn btn-red-hollow"
                href="/"
              >
                <b-icon-arrow-return-left />
                Voltar
              </a>
            </div>
          </div>
        </section>
        <!-- /fd-app-plataformas -->
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalPlataformaDesativar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Desativar a plataforma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalPlataformaDesativar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaFastPlataformaDesativar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalPlataformaReativar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Reativar a plataforma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalPlataformaReativar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaFastPlataformaReativar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalPlataformaExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Excluir a plataforma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalPlataformaExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaFastPlataformaExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalPlataformaAdministrador"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Se inserir como administrador na plataforma?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalPlataformaAdministrador')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaFastPlataformaInserirAdmin()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <!-- /fd-wrap -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageFormEffect01 from "@/assets/images/form-effect-01.png";
import imageSearchIcon from "@/assets/images/search-icon.svg";
import Autocomplete from "vuejs-auto-complete";

//require("@/assets/scss/app2.scss");

export default {
  name: "HomeInternoMinhasPlataformas",
  components: {
    Autocomplete,
  },
  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      fastIdPlataformaMaeFixa: settings.fastIdPlataformaMaeFixa,
      FastTheme: "",
      nomeUsuario: this.retornaSessao(settings.fastSessaoUsuario).First_name,
      imageFormEffect01,
      imageSearchIcon,
      buscandoCache: false,
      minhasPlataformasLoading: true
    };
  },
  computed: {
    RptMinhasPlataformasTemplate() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/RptMinhasPlataformasTemplate.vue`
        );
    },
    fastPlataformaInserirAdmin() {
      return this.$store.state.fastPlataformaInserirAdmin;
    },
    fastPlataformaDesativar() {
      return this.$store.state.fastPlataformaDesativar;
    },
    fastPlataformaReativar() {
      return this.$store.state.fastPlataformaReativar;
    },
    fastPlataformaExcluir() {
      return this.$store.state.fastPlataformaExcluir;
    },
  },
  watch: {
    fastPlataformaInserirAdmin(newCount, oldCount) {
      this.showModal("modalPlataformaAdministrador");
    },
    fastPlataformaDesativar(newCount, oldCount) {
      this.showModal("modalPlataformaDesativar");
    },
    fastPlataformaReativar(newCount, oldCount) {
      this.showModal("modalPlataformaReativar");
    },
    fastPlataformaExcluir(newCount, oldCount) {
      this.showModal("modalPlataformaExcluir");
    },
  },
  mounted: function () {
    this.minhasPlataformasLoading = true
    // Verifica sessão carrinho e armazena na store
    if (
      this.getFastSessao(settings.fastSessaoMinhasPlataformas) &&
      !this.getUrlParameter("novaPlataforma")
    ) {      
      this.buscandoCache = true;
      this.$store.state.fastMinhasPlataformas = this.getFastSessao(
        settings.fastSessaoMinhasPlataformas
      );
      this.$store.state.fastMinhasPlataformasLista = this.getFastSessao(
        settings.fastSessaoMinhasPlataformasLista
      );
      this.$store.state.fastUsuarioAdministra = this.getFastSessao(
        settings.fastSessaoUsuarioAdministra
      );      
      // loading
      setTimeout(() => {
        this.$store.state.fastCarregando = false;
        this.minhasPlataformasLoading = false
      }, 1000);
    } else {
      this.buscaPlataformasMaesFilhas();
    }
    
  },
  methods: {
    async buscaPlataformasMaesFilhas() {
      this.buscandoCache = false;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma/lista_minhas_plataformas",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let objPlataformas = Array.from(json);
        // Elimina replicados
        objPlataformas = Array.from(new Set(objPlataformas.map((a) => a.id_plataforma))).map((id_plataforma) => { return objPlataformas.find((a) => a.id_plataforma === id_plataforma); });
      
        // Lista utilizada no autocomplete
        this.$store.state.fastMinhasPlataformasLista = objPlataformas;
        this.setFastSessao(
          settings.fastSessaoMinhasPlataformasLista,
          this.$store.state.fastMinhasPlataformasLista
        );

        let plataformas_aluno = [];
        let plataformas_professor = [];
        let plataformas_secretario = [];
        let plataformas_tesouraria = [];
        let plataformas_responsavel = [];

        if (objPlataformas.length > 0) {
          // Reeordena array
          if (objPlataformas.length > 1) {
            let objPlataformasOrdenado = new Array();
            for (let i = 0; i < objPlataformas.length; i++) {
              // Aluno
              if (
                objPlataformas[i].id_usuario &&
                objPlataformas[i].aluno == "S" &&
                objPlataformas[i].ativo == "S"
              )
                plataformas_aluno.push(objPlataformas[i].id_plataforma);
              // Professor
              if (
                objPlataformas[i].id_usuario &&
                objPlataformas[i].professor == "S" &&
                objPlataformas[i].ativo == "S"
              )
                plataformas_professor.push(objPlataformas[i].id_plataforma);
              
              // Secretário
              if (
                objPlataformas[i].id_usuario &&
                objPlataformas[i].secretario == "S" &&
                objPlataformas[i].ativo == "S"
              )
                plataformas_secretario.push(objPlataformas[i].id_plataforma);
              
              // Tesouraria
              if (
                objPlataformas[i].id_usuario &&
                objPlataformas[i].tesouraria == "S" &&
                objPlataformas[i].ativo == "S"
              )
                plataformas_tesouraria.push(objPlataformas[i].id_plataforma);

                // Responsavel
              if (
                objPlataformas[i].id_usuario &&
                objPlataformas[i].responsavel == "S" &&
                objPlataformas[i].ativo == "S"
              )
                plataformas_responsavel.push(objPlataformas[i].id_plataforma);

              // Usuário administra uma plataforma então pode adicionar outras
              if (objPlataformas[i].administrador == "S")
                this.$store.state.fastUsuarioAdministra = true;
              if (objPlataformas[i].admin_fast == "S")
                this.$store.state.fastUsuarioAdminFast = true;
              this.setFastSessao(
                settings.fastSessaoUsuarioAdminFast,
                this.$store.state.fastUsuarioAdminFast
              );
              this.setFastSessao(
                settings.fastSessaoUsuarioAdministra,
                this.$store.state.fastUsuarioAdministra
              );

              for (let j = 0; j < objPlataformas.length; j++) {
                if (
                  objPlataformas[i].plataforma_mae ==
                  objPlataformas[j].id_plataforma
                ) {
                  //console.log("A mae de " + objPlataformas[i].nome_plataforma + " é " + objPlataformas[j].nome_plataforma);
                  let plataforma_existe = false;
                  for (let k = 0; k < objPlataformasOrdenado.length; k++) {
                    if (
                      objPlataformasOrdenado[k].id_plataforma ==
                      objPlataformas[j].id_plataforma
                    ) {
                      plataforma_existe = true;
                    }
                  }
                  if (!plataforma_existe) {
                    objPlataformasOrdenado.push(objPlataformas[j]);
                  }
                  plataforma_existe = false;
                  for (let k = 0; k < objPlataformasOrdenado.length; k++) {
                    if (
                      objPlataformasOrdenado[k].id_plataforma ==
                      objPlataformas[i].id_plataforma
                    ) {
                      plataforma_existe = true;
                    }
                  }
                  if (!plataforma_existe) {
                    objPlataformasOrdenado.push(objPlataformas[i]);
                  }
                }
              }
            }

            // Se o template possui uma plataforma mãe fixa
            let arrayPlataformasMae = [];
            if (settings.fastIdPlataformaMaeFixa) {
              arrayPlataformasMae = objPlataformasOrdenado.filter((p) => {
                if (p.id_plataforma == settings.fastIdPlataformaMaeFixa)
                  return p;
              });
            } else {
              arrayPlataformasMae = objPlataformasOrdenado.filter((p) => {
                if (p.plataforma_mae == "0") return p;
              });
            }

            let verificaNivelUltimos = (d) => {
              objPlataformasOrdenado.forEach(function (d2) {
                if (d.id_plataforma == d2.plataforma_mae) {
                  if (!d.nodes) d.nodes = [];
                  d.nodes.push(d2);
                  verificaNivelUltimos(d2);
                }
              });
            };

            let verificaNivel2 = (d, index) => {
              objPlataformasOrdenado.forEach(function (d2) {
                if (d.id_plataforma == d2.plataforma_mae) {
                  if (!arrayPlataformasMae[index].nodes)
                    arrayPlataformasMae[index].nodes = [];
                  arrayPlataformasMae[index].nodes.push(d2);
                  verificaNivelUltimos(d2);
                }
              });
            };

            arrayPlataformasMae.forEach(function (p, index) {
              verificaNivel2(p, index);
            });

            // Armazena na store e na sessão
            if (arrayPlataformasMae[0]) {
              this.$store.state.fastMinhasPlataformas = arrayPlataformasMae[0];
              if (arrayPlataformasMae[0].nodes.length) {
                this.setFastSessao(
                  settings.fastSessaoMinhasPlataformas,
                  this.$store.state.fastMinhasPlataformas
                );
              }
            }

            // Se o usuário for aluno de apenas uma plataforma e não for administrador ele irá ser redirecionado para a plataforma direto
            if (plataformas_aluno.length == 1 && !this.$store.state.fastUsuarioAdministra && !plataformas_professor.length && !plataformas_secretario.length && !plataformas_tesouraria.length && !plataformas_responsavel.length) {
              window.location.href = "/Plataforma/" + plataformas_aluno[0] + "/aluno";
            } else if (plataformas_professor.length == 1 && !this.$store.state.fastUsuarioAdministra && !plataformas_secretario.length && !plataformas_tesouraria.length && !plataformas_responsavel.length) {
              window.location.href = "/Plataforma/" + plataformas_professor[0] + "/professor";
            } else if (plataformas_secretario.length == 1 && !this.$store.state.fastUsuarioAdministra && !plataformas_professor.length && !plataformas_tesouraria.length && !plataformas_responsavel.length) {
              window.location.href = "/Plataforma/" + plataformas_secretario[0] + "/secretaria";
            } else if (plataformas_tesouraria.length == 1 && !this.$store.state.fastUsuarioAdministra && !plataformas_professor.length && !plataformas_secretario.length && !plataformas_responsavel.length) {
              window.location.href = "/Plataforma/" + plataformas_tesouraria[0] + "/tesouraria";
            } else if (plataformas_responsavel.length == 1 && !this.$store.state.fastUsuarioAdministra && !plataformas_professor.length && !plataformas_secretario.length && !plataformas_tesouraria.length) {
              window.location.href = "/Plataforma/" + plataformas_responsavel[0] + "/responsavel";
            }

            console.log("plataformas_aluno.length", plataformas_aluno.length)
            console.log("plataformas_professor.length", plataformas_professor.length)
            console.log("plataformas_secretario.length", plataformas_secretario.length)
            console.log("plataformas_tesouraria.length", plataformas_tesouraria.length)
            console.log("plataformas_responsavel.length", plataformas_responsavel.length)

            // loading
            setTimeout(() => {
              this.$store.state.fastCarregando = false;
            }, 1000);

            // Destaca nova plataforma
            setTimeout(() => {
              if (this.getUrlParameter("novaPlataforma")) {
                this.$store.state.fastMinhasPlataformasLista.forEach((p) => {
                  if (
                    p.nome_plataforma == this.getUrlParameter("novaPlataforma")
                  ) {
                    console.log("Plataforma nova: " + p.nome_plataforma);
                    //this.destacaPlataformaNova(p.nome_plataforma);
                    Array.from(
                      document.querySelectorAll("[fast-nome-plataforma]")
                    ).forEach((e) => {
                      e.classList.remove("bg-warning");
                      e.parentNode.classList.remove("collapse");
                    });
                    Array.from(
                      document.querySelectorAll("[fd-data-toggle='collapse']")
                    ).forEach((e) => {
                      if (e.querySelectorAll(".collapsed").length > 0)
                        e.click();
                    });

                    let divPlataforma = document.querySelector(
                      '[fast-nome-plataforma="' + p.nome_plataforma + '"]'
                    );
                    if (divPlataforma) {
                      divPlataforma.classList.add("bg-warning");
                      divPlataforma.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center",
                      });
                    }
                  }
                });
              }
            }, 3000);
          } else {
            this.$store.state.fastMinhasPlataformas = objPlataformas[0];
            this.$store.state.fastCarregando = false;            
          }
          this.minhasPlataformasLoading = false
        } else {
          this.$store.state.fastCarregando = false;
          this.exibeToasty(
            "Você não está cadastrado em nenhuma plataforma",
            "info"
          );     
          this.minhasPlataformasLoading = false     
        }
        //console.log(objPlataformas);
      } catch (e) {
        console.log("Erro", e);
        this.minhasPlataformasLoading = false
      }
    },
    destacaPlataformaNova(nome_plataforma) {
      console.log("Destacando " + nome_plataforma);
      Array.from(document.querySelectorAll("[fast-nome-plataforma]")).forEach(
        (e) => {
          e.classList.remove("bg-warning");
          e.parentNode.classList.remove("collapse");
        }
      );
      Array.from(
        document.querySelectorAll("[fd-data-toggle='collapse']")
      ).forEach((e) => {
        if (e.querySelectorAll(".collapsed").length > 0) e.click();
      });

      let divPlataforma = document.querySelector(
        '[fast-nome-plataforma="' + nome_plataforma.display + '"]'
      );
      if (divPlataforma) {
        divPlataforma.classList.add("bg-warning");
        divPlataforma.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    onChangeTheme(event) {
      this.FastTheme = event.target.value;
    },
    async confirmaFastPlataformaDesativar() {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma/desativa",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.$store.state.fastPlataformaDesativar)
          )
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.$store.state.fastPlataformaDesativar = {};
        this.buscaPlataformasMaesFilhas();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Plataforma desativada com sucesso",
        });
        this.hideModal("modalPlataformaDesativar");
      } catch (e) {
        console.log("Erro", e);
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao desativar plataforma",
        });
        this.$store.state.fastCarregando = false;
      }
    },
    async confirmaFastPlataformaReativar() {
      this.$store.state.fastCarregando = true;
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma/reativa",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.$store.state.fastPlataformaReativar)
          )
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        this.$store.state.fastPlataformaReativar = {};
        this.buscaPlataformasMaesFilhas();
        this.$notify({
          group: "foo",
          type: "success",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Plataforma reativada com sucesso",
        });
        this.hideModal("modalPlataformaReativar");
      } catch (e) {
        console.log("Erro", e);
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Erro ao reativar plataforma",
        });
        this.$store.state.fastCarregando = false;
      }
    },
    async confirmaFastPlataformaInserirAdmin() {
      this.$store.state.fastCarregando = true;

      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma/insere_admin",
          this.fastAjaxOptions(
            "POST",
            JSON.stringify(this.$store.state.fastPlataformaInserirAdmin)
          )
        );
        let json = await resp.json();
        console.log(json);
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        if (obj.length) {
          this.buscaPlataformasMaesFilhas();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Inserido com sucesso",
          });
        } else {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Não possível inserir seu usuário.",
          });
        }
        this.$store.state.fastPlataformaInserirAdmin = {};
        this.hideModal("modalPlataformaAdministrador");
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text: "Não possível inserir seu usuário.",
        });
        console.log("Erro", e);
      }
    },
    async confirmaFastPlataformaExcluir() {
      this.$store.state.fastCarregando = true;
      let model = {
        fast_plataforma_usuario: {
          id_plataforma: this.$store.state.fastPlataformaExcluir.id_plataforma,
        },
      };
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma/exclui",
          this.fastAjaxOptions("POST", JSON.stringify(model))
        );
        let json = await resp.json();
        let obj = Array.from(json);
        this.$store.state.fastCarregando = false;
        if (obj.length) {
          this.$notify({
            group: "foo",
            type: "error",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text:
              "Não possível excluir a plataforma. Verifique se essa plataformanão possui plataformas filhas.",
          });
        } else {
          this.buscaPlataformasMaesFilhas();
          this.$notify({
            group: "foo",
            type: "success",
            duration: 5000,
            speed: 1000,
            title: "Status",
            text: "Plataforma excluída com sucesso",
          });
        }
        this.$store.state.fastPlataformaExcluir = {};
        this.hideModal("modalPlataformaExcluir");
      } catch (e) {
        this.$store.state.fastCarregando = false;
        this.$notify({
          group: "foo",
          type: "error",
          duration: 5000,
          speed: 1000,
          title: "Status",
          text:
            "Não possível excluir a plataforma. Verifique as chaves estrangeiras.",
        });
        console.log("Erro", e);
      }
    },
  },
};
</script>

<style></style>
